.character-detail-container {
    display: flex;
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
    color: #fff;
    font-family: 'Orbitron', sans-serif; /* Game-inspired font */
  }
  
  .character-view {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .character-name {
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: center;
    color: #edf2f7; /* Neon green color for character name */
  }
  
  .character-canvas {
    width: 850px !important;
    height: 80vh !important;
    border: 3px solid #edf2f7; /* Glowing border effect */
    /* box-shadow: 0 0 20px #670e47(0, 255, 153, 0.7); */
    box-shadow: 0 0 10px #edf2f7;
    border-radius: 20px;
  }
  
  .character-customization {
    flex: 1;
    padding: 20px;
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ff0066; /* Neon pink for section titles */
    text-transform: uppercase;
  }
  
  .item-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .item-icon {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .item-icon.clickable {
    cursor: pointer;
    box-shadow: 0 0 10px #edf2f7; /* Neon glow effect */
  }
  
  .item-icon.clickable:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #edf2f7
  }
  
  .item-icon.disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
  }
  
  .loading-screen {
    font-size: 1.5rem;
    color:#edf2f7;
    text-align: center;
    margin-top: 50px;
  }
  
  .card-avthar {
  
    transition: 0.3s;
  }
  
  .card-avthar:hover {
    transform: scale(0.5);
  }