.seiky-platforms{
    padding:0
}
.seiky-platforms .seiky-platforms__list{
    display:flex;
    flex-wrap:wrap
}
.seiky-platforms .seiky-platforms__item{
    padding:0;
    flex:0 0 50%;
    max-width:50%;
    padding-top:35%;
    position:relative;
    z-index:2;
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat
}
.seiky-platforms .seiky-platform__bg-wrapper{
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:-1
}
.seiky-platforms .seiky-platform__content-wrapper{
    position:absolute;
    top:20%
}
.seiky-platforms .left_group .seiky-platform__content-wrapper{
    left:26%
}
.seiky-platforms .seiky-platform__title{
    margin:0;
    color:#fff;
    font-weight:800
}
.seiky-platforms .seiky-platform__content p{
    margin:1em 0
}
.seiky-platforms .seiky-platform__content{
    font-weight:400;
    display:inline-block;
    width:100%
}
.seiky-platforms .btn_small{
    color:#FFF;
    background:#000;
    border:2px solid #000;
    line-height:1.25rem;
    padding:.5rem 1.5rem;
    border-radius:22.5px;
    font-size:.875rem;
    letter-spacing:-0.28px;
    font-weight:500;
    width:auto;
    display:table;
    text-align:center;
    margin-top:5rem;
    text-transform:uppercase;
    min-width:162px
}
.seiky-platforms .btn_small:hover{
    border:2px solid #fff;
    color:#000;
    background:#fff
}
.seiky-platforms .btn_small:focus{
    border:2px solid #fff;
    color:#000;
    background:#fff
}
.seiky-platforms .seiky-platforms__item.th_white *{
    color:#fff
}
.seiky-platforms .right_group .seiky-platform__content-wrapper{
    left:10%
}
.seiky-platforms .seiky-platforms__item.th_white .btn_small{
    color:#000;
    background:#FFF;
    border:2px solid #FFF
}
.seiky-platforms .seiky-platforms__item.th_white .btn_small:hover{
    border:2px solid #fff;
    color:#fff;
    background:#000
}
.seiky-platforms .seiky-platforms__item.th_white .btn_small:focus{
    border:2px solid #fff;
    color:#fff;
    background:#000
}
@media(max-width:991.98px){
    .seiky-platforms .seiky-platforms__item{
        padding-top:140%;
        width:100%;
        padding-right:.5rem;
        padding-left:.5rem;
        flex:0 0 100%;
        max-width:100%;
        margin-bottom:20px
    }
    .seiky-platforms .left_group .seiky-platform__content-wrapper{
        left:1rem;
        top:auto;
        bottom:10%
    }
    .seiky-platforms .btn_small{
        margin-top:6.5rem
    }
    .seiky-platforms .right_group .seiky-platform__content-wrapper{
        top:auto;
        left:2rem;
        bottom:10%
    }
    .seiky-platforms{
        padding:0 20px
    }
}
@media(max-width:768px){
    .seiky-platforms .btn_small{
        font-size:.75rem
    }
}
