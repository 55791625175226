.newsroom-section {
    float: left;
    width: 100%;
}
.newsroom-section h3 { color: #fff; text-align: left;  color: #FFFFFF; text-transform: uppercase;margin-bottom: 25px; }
.webinars-lists { display: inline-flex;  width: 100%;}
.web-list-item {  display: inline-flex;  width: 100%;  position: relative;}
.wli-img {  width: 50%; position: relative;  height: 100%;}
.wli-img>a {
    float: left;
    width: 100%;
    height: 100%;
    max-height: 281px;
}
.wli-img img { width: 100%; height: 100%!important;  object-fit: cover;}
.wli-content { width: 38%; position: relative; display: inline-flex; align-content: center; flex-wrap: wrap;  padding: 40px;}
.wli-content.no-date { width: 50%;}
.wli-date { position: relative; width: 12%; align-content: center; color: #fff; z-index: 1; display: inline-flex;
    flex-wrap: wrap; align-items: center;  text-align: center;  justify-content: center;}
.wli-title-wrap .wli-date { display:none }


.web-list-item:before, .press-list-item:before  {  position: absolute;  width: 100%; height: 100%;  content: "";  background: #fff;  filter: opacity(0.15);}
.wli-content h3:first-child {  color: #01153a;}
.wli-content h3 a{color:#fff;}
.wli-content h3 {text-transform: uppercase; float: left; width: 100%; 
  font: normal normal bold 20px/24px Montserrat;
  font-size:20px;
  line-height:24px;
  font-weight:700;
  margin-bottom: 13px;}
.wli-content p, .press-content p { margin-bottom: 0;   color: #fff;}
.wli-date:before {  content: "";  background: #41a1b0;  filter: opacity(0.45); position: absolute;  width: 100%;  height: 100%;}
.newsroom-section .webinars-lists .date { 
  font: normal normal bold 50px/50px Montserrat;  
  font-size:50px;
  line-height:50px;
  font-weight:700;
  color: #fff;  position: relative; text-transform: uppercase; float: left;
    width: 100%; }
.newsroom-section .webinars-lists .month {  color: #fff;  position: relative; text-transform: uppercase;}
.bottom-post-wrap { margin-top: 75px; float: left;  width: 100%;  display: inline-flex;  justify-content: space-between;}
.bottom-post-wrap .news-post {  width: 48.5%;}
.press-list-item { position: relative;}
.press-img {  width: 100%;  height: 280px;  float: left;  display: block; position: relative;}
.press-img img {  width: 100%;  height: 100% !important;  object-fit: cover;}
.press-content { float: left; width: 100%; display: block;  padding: 20px 30px 27px; position: relative; min-height: 160px;}
.press-list-item { position: relative; float: left;  width: 100%;}
.press-content .date-time { color: #fff;  font-size:16px; flex-wrap: wrap;
    display: inline-flex; width: 100%;  justify-content: space-between;  margin-bottom: 18px;text-transform: uppercase;}
.press-content .date-time ul.date {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.press-content .date-time ul.date li {
    float: left;
    width: auto;
    border-right: 2px solid #fff;
    padding-right: 10px;
    margin-right: 10px;
  font-size: 16px;
    line-height: 16px;
}
.press-content .date-time ul.date li:last-child {
    padding-right: 0;
    margin-right: 0;
    border: none;
}

.news-btn-bottom a {
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  color: #000;
  background: #FFF;
  border: 2px solid #FFF;
  padding: .5rem 1.5rem;
  border-radius: 22.5px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: .5rem 1.5rem;
  border-radius: 22.5px;
  font-size: .75rem;
  line-height: 1.25rem;
  width: max-content;
  display: block; letter-spacing: -0.28px;
  margin-top: 2rem; min-width:162px; text-align:center;
}

.news-btn-bottom a:hover {
  border: 2px solid #000;  color:#fff; background: #000;
}

.news-btn-bottom a:focus {
  background: #fff; color:#000;
  border: 2px solid #000;
}
.news-btn-bottom {
    float: left;
    margin: 60px auto 0 auto;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    width: 100%;
}
@media only screen and (min-width: 220px) and (max-width: 991px){
  .web-list-item, .webinars-lists{flex-wrap:wrap;}
  .newsroom-section { padding: 0 10px;}
  .wli-img{ width: 100%;  height: 196px;}
  .wli-date{display:none;}
  .wli-content{width: 100%;  padding:20px 17px 40px;}
  .wli-content.no-date { width: 100%;}
  .bottom-post-wrap {flex-wrap:wrap;  margin-top: 0px;}
  .wli-content p, .press-content p{
    font-size: 18px;
    line-height: 24px;
  }
  .bottom-post-wrap .news-post { margin-top:50px; width: 100%;}
/*  .press-content .date{margin-bottom: 10px;}
   .newsroom-section h3 { text-align: center;} */
  .newsroom-section h3 { margin-bottom: 20px;}
  .news-btn-bottom {  margin: 30px auto 0 auto;}
  .wli-title-wrap {
      display: flex;
      align-items: flex-end;
      float: left;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;
  }
  .wli-title-wrap .wli-date { display:inline-flex; width: 25%; }
  .newsroom-section .webinars-lists .date {
      font-size: 33px;
      line-height: 33px;
  }
  .newsroom-section .webinars-lists .month {
      font-size: 13px;
  }
  .newsroom-section .wli-title-wrap h3 {
      margin-bottom: 0;
  }
  .wli-title-wrap .wli-date:before {
      display: none;
  }
  .press-content {
      padding: 20px 17px 27px;
  }
}
@media(max-width:600px){  
  .press-img{height: auto;}
}



a.cta_button {
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
    vertical-align: middle
}

.hs-breadcrumb-menu {
    list-style-type: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px
}

.hs-breadcrumb-menu-item {
    float: left;
    padding: 10px 0px 10px 10px
}

.hs-breadcrumb-menu-divider:before {
    content: '›';
    padding-left: 10px
}

.hs-featured-image-link {
    border: 0
}

.hs-featured-image {
    float: right;
    margin: 0 0 20px 20px;
    max-width: 50%
}

@media (max-width: 568px) {
    .hs-featured-image {
        float: none;
        margin: 0;
        width: 100%;
        max-width: 100%
    }
}

.hs-screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px
}


.widget_1628149284119.features-info .features__item {
    min-height: 569px;
    padding: 2rem;
}


@media (max-width: 991.98px) {

    .widget_1628149284119.features-info .features__item {
        min-height: 475px;
    }

}



@media (max-width: 575.98px) {

    #mgd-features.features-info .section__bg-wrapper {
        background-image: url() !important;
    }

}


.widget_1628150654006.cross-platforms .cross-platforms__title {
    color: rgba(0, 249, 241, 1.0);
}

.widget_1628150654006.cross-platforms .platform__icon svg {
    fill: rgba(255, 255, 255, 1.0);
}

.widget_1628150654006.cross-platforms .platform__name {
    color: #01153a;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .cross-platforms {
        
    }

}

@media (min-width:993px) {
    .trusted-partners {
        background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0) 100%);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0) 100%);
        background-repeat: no-repeat;
    }

}

#hs_cos_wrapper_widget_1637037239210 {
    color: #01153a !important;
    display: block !important;
    padding-bottom: 20px !important;
    text-align: center !important
}

#hs_cos_wrapper_widget_1637037239210 p,
#hs_cos_wrapper_widget_1637037239210 li,
#hs_cos_wrapper_widget_1637037239210 span,
#hs_cos_wrapper_widget_1637037239210 label,
#hs_cos_wrapper_widget_1637037239210 h1,
#hs_cos_wrapper_widget_1637037239210 h2,
#hs_cos_wrapper_widget_1637037239210 h3,
#hs_cos_wrapper_widget_1637037239210 h4,
#hs_cos_wrapper_widget_1637037239210 h5,
#hs_cos_wrapper_widget_1637037239210 h6 {
    color: #01153a !important
}

#hs_cos_wrapper_widget_1637037239210 {
    color: #01153a !important;
    display: block !important;
    padding-bottom: 20px !important;
    text-align: center !important
}

#hs_cos_wrapper_widget_1637037239210 p,
#hs_cos_wrapper_widget_1637037239210 li,
#hs_cos_wrapper_widget_1637037239210 span,
#hs_cos_wrapper_widget_1637037239210 label,
#hs_cos_wrapper_widget_1637037239210 h1,
#hs_cos_wrapper_widget_1637037239210 h2,
#hs_cos_wrapper_widget_1637037239210 h3,
#hs_cos_wrapper_widget_1637037239210 h4,
#hs_cos_wrapper_widget_1637037239210 h5,
#hs_cos_wrapper_widget_1637037239210 h6 {
    color: #01153a !important
}

.DND_banner-row-0-force-full-width-section>.row-fluid {
    max-width: none !important;
}

.middle_area-row-0-force-full-width-section>.row-fluid {
    max-width: none !important;
}

.middle_area-row-1-force-full-width-section>.row-fluid {
    max-width: none !important;
}

.middle_area-row-2-force-full-width-section>.row-fluid {
    max-width: none !important;
}

.bottom_area-row-0-force-full-width-section>.row-fluid {
    max-width: none !important;
}

/* HubSpot Styles (default) */
.DND_banner-row-0-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.widget_1636703820351-hidden {
    display: none !important;
}

.top_area-row-0-background-layers {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
    background-color: #01153a !important;
}

.module_1628146879392-hidden {
    display: block !important;
}

.widget_1628146839328-hidden {
    display: none !important;
}

.middle_area-row-0-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.middle_area-row-1-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.middle_area-row-2-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.middle_area-row-2-background-layers {
    background-image: url('../images/bg2.webp') !important;
    background-position: left top !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.cell_16281492845812-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.cell_16281506543652-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.widget_1628151490928-hidden {
    display: block !important;
}

.cell_16281519336752-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bottom_area-row-0-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bottom_area-row-0-background-layers {
    background-image: url('../images/bg3.webp') !important;
    background-position: center bottom !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.bottom_area-row-1-background-layers {
    background-image: url('../images/testimonials_bg-2.webp') !important;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.bottom_area-row-2-background-layers {
    background-image: linear-gradient(to bottom, rgba(1, 21, 58, 1), rgba(1, 21, 58, 1)) !important;
    background-position: left top !important;
    background-size: auto !important;
    background-repeat: no-repeat !important;
}

.widget_1628229393288-hidden {
    display: none !important;
}

/* HubSpot Styles (mobile) */
@media (max-width: 991.98px) {
    .module_1628146879392-hidden {
        display: none !important;
    }

    .widget_1628146839328-hidden {
        display: block !important;
    }
}


#hs_cos_wrapper_widget_1637037239210  { color: #01153a !important; display: block !important; padding-bottom: 20px !important; text-align: center !important }
#hs_cos_wrapper_widget_1637037239210  p , #hs_cos_wrapper_widget_1637037239210  li , #hs_cos_wrapper_widget_1637037239210  span , #hs_cos_wrapper_widget_1637037239210  label , #hs_cos_wrapper_widget_1637037239210  h1 , #hs_cos_wrapper_widget_1637037239210  h2 , #hs_cos_wrapper_widget_1637037239210  h3 , #hs_cos_wrapper_widget_1637037239210  h4 , #hs_cos_wrapper_widget_1637037239210  h5 , #hs_cos_wrapper_widget_1637037239210  h6  { color: #01153a !important }
#hs_cos_wrapper_widget_1637037239210  { color: #01153a !important; display: block !important; padding-bottom: 20px !important; text-align: center !important }
#hs_cos_wrapper_widget_1637037239210  p , #hs_cos_wrapper_widget_1637037239210  li , #hs_cos_wrapper_widget_1637037239210  span , #hs_cos_wrapper_widget_1637037239210  label , #hs_cos_wrapper_widget_1637037239210  h1 , #hs_cos_wrapper_widget_1637037239210  h2 , #hs_cos_wrapper_widget_1637037239210  h3 , #hs_cos_wrapper_widget_1637037239210  h4 , #hs_cos_wrapper_widget_1637037239210  h5 , #hs_cos_wrapper_widget_1637037239210  h6  { color: #01153a !important }
.DND_banner-row-0-force-full-width-section > .row-fluid {
  max-width: none !important;
}
.middle_area-row-0-force-full-width-section > .row-fluid {
  max-width: none !important;
}
.middle_area-row-1-force-full-width-section > .row-fluid {
  max-width: none !important;
}
.middle_area-row-2-force-full-width-section > .row-fluid {
  max-width: none !important;
}
.bottom_area-row-0-force-full-width-section > .row-fluid {
  max-width: none !important;
}
/* HubSpot Styles (default) */
.DND_banner-row-0-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.widget_1636703820351-hidden {
  display: none !important;
}
.top_area-row-0-background-layers {
  background-image: url('../images/Publisher.jpg') !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* background-color: #01153a !important; */
}
.module_1628146879392-hidden {
  display: block !important;
}
.widget_1628146839328-hidden {
  display: none !important;
}
.middle_area-row-0-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.middle_area-row-1-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.middle_area-row-2-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.middle_area-row-2-background-layers {
  background-image: url('../images/bg2.webp') !important;
  background-position: left top !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.cell_16281492845812-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.cell_16281506543652-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.widget_1628151490928-hidden {
  display: block !important;
}
.cell_16281519336752-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.bottom_area-row-0-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.bottom_area-row-0-background-layers {
  background-image: url('../images/bg3.webp') !important;
  background-position: center bottom !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.bottom_area-row-1-background-layers {
  background-image: url('../images/testimonials_bg-2.webp') !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.bottom_area-row-2-background-layers {
  background-image: linear-gradient(to bottom, rgba(1, 21, 58, 1), rgba(1, 21, 58, 1)) !important;
  background-position: left top !important;
  background-size: auto !important;
  background-repeat: no-repeat !important;
}
.widget_1628229393288-hidden {
  display: none !important;
}
/* HubSpot Styles (mobile) */
@media (max-width: 991.98px) {
  .module_1628146879392-hidden {
    display: none !important;
  }
  .widget_1628146839328-hidden {
    display: block !important;
  }
}


.features-info {
    position: relative;
    z-index: 1;
    padding: 7.5rem 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .features-info .section__bg-wrapper {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  
  .features-info .section__bg-img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    opacity: 0;
    visibility: hidden;
  }
  
  .features-info .features-info__container {
    position: relative;
    width: 100%;
    padding-right: .5rem;
    padding-left: .5rem;
        z-index: 2;
  }
  
  .features-info .features-info__content-wrapper {
    margin-left: 3.5rem;
  }
  
  .features-info h2.features-info__title {
    
    font-weight: 800;
    color: #01153a;
    width: auto;
    display: inline-block;
    position: relative;
    margin: 0;
    text-transform: uppercase;
  }
  
  .features-info .features-info__content {
    margin-top: 2rem;
    color: #FFF;
  }
  
  .features-info .features-info__content p {
    margin: 0;
  }
  
  .features-info .features-info__content-wrapper .hs-button {
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    color: #000;
    background: #FFF;
    border: 2px solid #FFF;
    padding: .5rem 1.5rem;
    border-radius: 22.5px;
    font-weight: 400;
    line-height: 1.25rem;
    padding: .5rem 1.5rem;
    border-radius: 22.5px;
    font-size: .875rem;
    line-height: 1.25rem;
    width: max-content;
    display: block;
    margin-top: 2rem;
  }
  
  .features-info .features-info__content-wrapper .hs-button:hover {
    border: 2px solid #000;  color:#fff; background: #000;
  }
  
  .features-info .features-info__content-wrapper .hs-button:focus {
    background: #fff; color:#000;
    border: 2px solid #000;
  }
  
  
  .features-info .features__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  
  .features-info .features__item {
    position: relative;
    height: 310px;
    padding: 3.2rem;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  
  .features-info .feature__bg-wrapper {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  
  .features-info .feature__icon {
    width: auto;
    height: 50px;
    display: block;
  }
  
  .features-info .feature__icon svg {
    height: 100%;
  }
  
  .features-info h3.feature__title {
    width: auto;
    display: inline-block;
    position: relative;
    margin: 0;
    color: #01153a;  
    font-weight: 800;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  
  .features-info .feature__content {
    margin-top: 1rem; 
    
    color: #FFF;
  }
  
  .features-info .feature__content p {
    margin: 0;
  }
  
  .features-info .features-info__features {
    margin-top: 30px;
  }
  
  .features-info .features__item:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 69%;
      background: #000;
      left: 0;
      backdrop-filter: blur(50px);
      top: 0;
      z-index: -1;
      background: linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(253,187,45,0) 100%);
  }
  
  #mgd-features h2.features-info__title {
      color: #fff;
      text-align: center;
      margin-bottom: 5rem;
      line-height: 1.2;
  }
  #mgd-features {
      max-height: 638px;
  }
  #mgd-features .widget_1639068155639.features-info .features__item {
      padding: 2.688rem;
  }
  #mgd-features.features-info h3.feature__title {
      margin-top: 1.563rem;
  }
  
  
  @media (min-width: 768px) and (max-width: 1199.98px){
  
    
    #mgd-features {
        max-height: 618px;
    }
  
  }
  
  @media (max-width: 991.98px){
  
    .features-info .features-info__container {
      max-width: 100%;
      margin: 0;
      margin-top: 375px;
    }
    .hm-page .features-info .features__item{    margin-left: 0rem !important;}
    .features-info .features-info__content-wrapper {
      max-width: none;
      margin-left: 0;
      padding: 0 1rem;
    }
      
    .features-info .features__item {
      width: 100%;
      flex: 0 0 100%; 
      max-width: 100%;
      padding: 2rem !important;
    }
  
    .features-info .features__item:first-child,
    .features-info .features__item:nth-child(4) {
      margin-left: 1rem;
      width: calc(100% - 1rem);
      flex: 0 0 calc(100% - 1rem);
      max-width: calc(100% - 1rem);
    }
  
    .features-info .features__item:last-child {
      margin-right: 2rem;
    }
    
    #mgd-features .desktop-only {
        display: block;
    }
    #mgd-features.features-info .features-info__container {
        margin-top: 0;
    }
    #mgd-features {
        max-height: inherit;
        padding: 4rem 0;
    }
    #mgd-features h2.features-info__title {
        margin-bottom: 2.5rem;
    }
    #mgd-features.features-info .features__item {
        position: relative;
        height: 310px;
        padding: 3.2rem;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin: 0;
    }
  
  }
  
  @media (max-width: 767.98px){
  
    
    .features-info .features-info__content-wrapper .hs-button {font-size: .75rem;}
    .features-info .features__list {
      display: grid;
      grid-template-columns: 85% 85% 85%;
      overflow-x: auto;
    }
    
    .features-info .features-info__content {
      margin-top: 20px;
    }
  
    .features-info {
      padding: 5rem 0;
    }
  
    .features-info .features__item:first-child,
    .features-info .features__item:nth-child(4) {
      margin-left: 1rem;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    #mgd-features.features-info .features__item {
        position: relative;
        height: 310px;
        padding: 3.2rem;
        max-width: inherit;
        margin: 0;
    }
  
    
    #mgd-features h2.features-info__title br {
        display: none;
    }
    #mgd-features h2.features-info__title {
        margin-bottom: 0.3rem;
       
    }
    .features-info .features__item:before {   
      background: rgba(222,222,222,0.8);
    }
  }
  
  
  @media (max-width: 575.98px){
  
    .features-info .features-info__container {
      padding-right: 0;
      padding-left: 0;
    }
    #mgd-features.features-info .features__item {
        position: relative;
        height: auto;
        min-height: inherit;
        padding: 3.2rem;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin:0;
    }
    #mgd-features.features-info .features__list {
        padding: 0 0 0 20px;
    }
    #mgd-features {
        padding: 286px 0 0;
    }
    #mgd-features h2.features-info__title {
        margin-bottom: 3rem;
    }
  }