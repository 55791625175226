.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00e5ff; /* Button background color */
    color: #000; /* Button text color */
    font-weight: bold;
    font-family: Arial, sans-serif;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  /* Add hover effect */
  .custom-button:hover {
    background-color: #00b0d5;
  }

  /* Icon container */
  .button-icon {
    width: 30px;
    height: 30px;
    background-image: url('../images/mobile.png'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    margin-right: 10px; /* Spacing between icon and text */
  }



  .form-container {
    width: 100%;
    padding: 20px;
    /* background: linear-gradient(to bottom, #00ffff 2%, #01153a 98%); */
    background: #00ffff;
    border-radius: 10px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); */
  }

  .form-header {
    font-size: 24px;
    font-weight: bold;
    color: #01153a;
    text-align: center;
    margin-bottom: 20px;
  }

  .form-row {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }

  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .form-group label {
    font-size: 14px;
    color: #01153a;
    margin-bottom: 5px;
  }

  .form-group select,
  .form-group input,
  .form-group textarea {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 5px;
    color: #01153a;
    padding: 10px;
    font-size: 14px;
    outline: none;
  }

  /* Placeholder color */
.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #01153a !important; /* Placeholder text color */
  opacity: 0.7 !important; /* Optional: Adjust opacity for lighter look */
}


  .form-group textarea {
    resize: none;
  }

  .form-group select option {
    background: #000000;
    color: #ffffff;
  }

  .form-submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .form-submit button {
    background-color: #00ffff;
    border: none;
    border-radius: 20px;
    color: #01153a;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s;
  }

  .form-submit button:hover {
    background-color: #01153a;
  }


  .layout-2.site-content__section {
    width: 100%;
    float: left;
  }

  .layout-2 .content-area {
    max-width: 40%;
    float: left;
  }

  .layout-2 .trusted-partners__carousel-wrapper {
    width: 50%;
    float: right;
  }

  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
    max-width: 50%;
    margin-top: 2rem;
  }

  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item:nth-child(1),
  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item:nth-child(2) {
    margin-top: 0px;
  }

  .layout-2 .trusted-partners__title,
  .layout-2 .trusted-partners__content {
    text-align: left !important;
    color: #000000 !important;
  }

  .layout-1 .hs_cos_wrapper_type_cta {
    display: none;
  }

  .layout-2 .hs_cos_wrapper_type_cta .cta_button {
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
    color: #fff;
    background: #000000;
    border: 2px solid #000000;
    padding: 0.5rem 1.5rem;
    border-radius: 22.5px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.5rem 1.5rem;
    border-radius: 22.5px;
    font-weight: 400;
    line-height: 1.25rem;
    width: max-content;
    display: block;
    margin-top: 3.5rem;
  }

  .layout-2.trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: inherit;
  }

  .trusted-partners h2.trusted-partners__title {
    text-align: center;
    margin: 0;
    color: #01153a
  }

  .trusted-partners .trusted-partners__content {
    margin-top: 1rem;

    font-weight: 400;

    color: #FFF;
    text-align: center
  }

  .trusted-partners .trusted-partners__content p {
    margin: 0
  }

  .trusted-partners .trusted-partners__carousel .img-responsive {
    height: auto;
    width: auto;
    max-width: 100%
  }

  .trusted-partners .trusted-partners__carousel .sliderDesktop .img-responsive {
    height: 80px;
    width: auto
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots {
    position: static;
    margin-top: 4rem
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots button {
    display: none
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots li {
    margin: 0 4px;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, .6);
    opacity: .5;
    display: inline-block;
    border-radius: 100%;
    cursor: pointer
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots li.slick-active {
    background: #FFF;
    opacity: 1
  }

  .trusted-partners .trusted-partners__list.sliderDesktop {
    margin-top: 5rem;
    margin-bottom: 0 !important
  }

  .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
    margin-right: -100px
  }

  .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
    margin-top: 0;
    padding-bottom: 4px;
    margin-right: 100px;
    text-align: center;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
  }

  .trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: hidden
  }

  #mgd-partners.trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: visible;
  }

  #mgd-partners.trusted-partners h2.trusted-partners__title {
    text-align: center;
    margin: 0 0 25px;
    color: #fff;
    max-width: inherit;
  }

  @media(min-width: 992px) {
    .trusted-partners .trusted-partners__list.sliderMobile {
      display: flex;
      flex-wrap: wrap;
      margin-right: -.5rem;
      margin-left: -.5rem;
      justify-content: space-around;
      padding: 0
    }

    .trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      flex: inherit;
      max-width: 160px;
      padding: 0;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 4rem;
      align-items: center;
      display: flex;
    }

    .trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__image-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-height: 70px;
      height: auto;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center
    }

    #mgd-partners.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      max-width: 235px;
    }
  }



  @media(max-width: 991px) {

    .trusted-partners .trusted-partners__carousel .slick-track {
      margin: 0;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }

    .trusted-partners .trusted-partners__carousel .slick-track .trusted-partners__item {
      margin-top: 3rem;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0
    }

    .trusted-partners .trusted-partners__carousel .slick-track .trusted-partners__image-wrapper {
      height: 55px;
      width: auto
    }

    .trusted-partners .slick-slide>div {
      margin-top: 3rem;
      width: calc(50% - 3rem);
      display: inline-block;
      margin-right: 3rem
    }

    .trusted-partners .slick-slide>div img.trusted-partners__image {
      height: 55px;
      width: auto
    }

    .trusted-partners .trusted-partners__carousel .slick-slider {}

    .trusted-partners h2.trusted-partners__title {
      max-width: 60%;
      display: block;
      margin: 0 auto;
      text-align: center
    }



    .trusted-partners h2.trusted-partners__title {
      max-width: 60%;
      display: block;
      margin: 0 auto;
      text-align: center
    }



    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item .trusted-partners__image-wrapper {
      margin-bottom: 2rem;
      margin-top: 0;
      margin-right: 0;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
      flex: 0 0 25%;
      max-width: 25%;
      margin: 0;
      margin-right: 50px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
      margin-right: -50px;
    }

    .trusted-partners .trusted-partners__carousel .sliderDesktop .img-responsive {
      height: 55px;
      width: auto;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__item {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper {
      width: 100%;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(50% - 1.5rem);
      margin-right: 1.5rem;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div:nth-child(even) {
      margin-left: 1.5rem;
      margin-right: 0;
    }

    /*   .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__carousel {
padding: 0 15px;
} */
    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper img.trusted-partners__image {
      display: block;
      margin: 0 auto;
    }

    .layout-2 .trusted-partners__title,
    .layout-2 .trusted-partners__content {
      max-width: 100% !important
    }

    .layout-2.trusted-partners .trusted-partners__list.sliderMobile div:nth-child(1),
    .layout-2.trusted-partners .trusted-partners__list.sliderMobile div:nth-child(2) {
      margin-top: 0px;
    }

    .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      max-width: 100%;
    }

    .layout-2.trusted-partners .slick-slide>div {
      margin-top: 1rem;
    }

    .features-info .features-info__content .mobile-only br {
      display: block;
    }
  }

  @media (max-width:767px) {
    .layout-2 .hs_cos_wrapper_type_cta .cta_button {
      font-size: 0.75rem
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0;
      margin-right: 30px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
      margin-right: -30px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-slide>div {
      margin-right: 30px;
      width: calc(50% - 30px);
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .layout-2 .content-area {
      max-width: 100%;
    }

    .layout-2 .trusted-partners__carousel-wrapper {
      width: 100%;
      margin-top: 40px;
    }

    .layout-2 .slick-track {
      width: 100% !important;
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(33% - 0rem);
      margin: 0rem !important;
    }

    .layout-2 .trusted-partners__title,
    .layout-2 .trusted-partners__content {
      text-align: center !important;
    }

    .layout-2 .hs_cos_wrapper_type_cta .cta_button {
      margin-left: auto;
      margin-right: auto;
    }

    #mgd-partners {
      padding: 1rem 0;
    }

    #mgd-partners.trusted-partners h2.trusted-partners__title br {
      display: none;
    }

  }


  @media(max-width: 575.98px) {
    .trusted-partners .trusted-partners__container {
      margin-top: 3rem;
      width: calc(50% - 2rem);
      display: inline-block;
      height: 55px;
      margin-right: 2rem;
    }

    .trusted-partners .slick-slide>div:nth-child(even) {
      margin-right: 0;
      margin-left: 2em;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container {
      height: auto;
      width: 100%;
      margin-right: auto !important;
      margin-left: auto !important;
      display: block;
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(50% - 0rem);
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container {
      margin-top: 0px;
    }

    .slick-initialized .slick-slide {
      display: flex;
      flex-wrap: wrap;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper {
      width: 100%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      height: auto;
      padding: 0 15px;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper img.trusted-partners__image {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      float: left;
    }

    .features-info {
      padding: 286px 0 5rem;
    }

  }


  .cross-platforms {
    height: 850px;
  }

  .cross-platforms .cross-platforms__container {
    height: 100%;
    position: relative;
  }

  .cross-platforms .cross-platforms__content-wrapper {
    position: absolute;
    bottom: 3.5rem;
    left: 0%;
  }

  .cross-platforms .cross-platforms__title {
    margin: 0;
  }

  .cross-platforms .platforms__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .platforms__list>a {
    margin-left: 0;
    text-align: center;
    backdrop-filter: blur(30px);
    width: 48%;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  .cross-platforms .platforms__item {
    margin-left: 0;
    text-align: center;
    background: #00ffff;
    border-radius: 50px;
    backdrop-filter: blur(30px);
    width: 60%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin: 20px;
  }

  .cross-platforms .platforms__item:first-child {
    margin-left: 0;
  }

  .cross-platforms .platform__icon {
    width: 55px;
    height: auto;
  }

  .cross-platforms .platform__icon svg {
    max-width: 100%;
  }

  .cross-platforms .platform__name {
    display: block;
    margin-left: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
  }

  .cross-platforms .cross-platforms__platforms {
    margin-top: 2rem;
  }

  span.platform__icon.typesvg span {
    display: block;
    width: 100%;
  }

  span.platform__icon.typesvg span svg {
    width: 55px;
  }

  .cross-platforms .platform__icon.typesvg svg {
    width: 55px;
  }



  @media (max-width: 991.98px) {


    .section__bg-wrapper {
      background-position: left bottom;
    }

    .cross-platforms .cross-platforms__title {
      max-width: 100%;
    }

    .cross-platforms .cross-platforms__platforms {
      margin-bottom: 3rem;
    }

  }


  @media (max-width: 767.98px) {



    .cross-platforms .cross-platforms__title {
      margin: auto;
    }

    .cross-platforms .cross-platforms__platforms {
      margin-bottom: 3rem;
    }

    .cross-platforms {
      height: auto;
      padding-top: calc(7.5rem + 600px / 2);
      padding-bottom: 50%;
    }

    .cross-platforms .cross-platforms__content-wrapper {
      position: static;
      text-align: center;
    }

    .cross-platforms .platforms__item {
      background: rgb(31 64 77 / 41%);
    }

    .cross-platforms .platforms__list {
      -webkit-box-pack: center;
      -ms-flex-pack: center;

      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

  }

  @media (max-width: 480px) {

    .cross-platforms .platform__icon {
      width: 49px;
    }

    .cross-platforms .platform__name br {
      display: none;
    }

    .cross-platforms .platforms__item {
      margin-bottom: 15px;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }

    .cross-platforms .platform__name {
      float: left;
      width: 100%;
      margin: 15px auto 0 auto;
    }
  }

  .layout-2.site-content__section {
    width: 100%;
    float: left;
  }

  .layout-2 .content-area {
    max-width: 40%;
    float: left;
  }

  .layout-2 .trusted-partners__carousel-wrapper {
    width: 50%;
    float: right;
  }

  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
    max-width: 50%;
    margin-top: 2rem;
  }

  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item:nth-child(1),
  .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item:nth-child(2) {
    margin-top: 0px;
  }

  .layout-2 .trusted-partners__title,
  .layout-2 .trusted-partners__content {
    text-align: left !important;
    color: #000000 !important;
  }

  .layout-1 .hs_cos_wrapper_type_cta {
    display: none;
  }

  .layout-2 .hs_cos_wrapper_type_cta .cta_button {
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
    color: #fff;
    background: #000000;
    border: 2px solid #000000;
    padding: 0.5rem 1.5rem;
    border-radius: 22.5px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.5rem 1.5rem;
    border-radius: 22.5px;
    font-weight: 400;
    line-height: 1.25rem;
    width: max-content;
    display: block;
    margin-top: 3.5rem;
  }

  .layout-2.trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: inherit;
  }

  .trusted-partners h2.trusted-partners__title {
    text-align: center;
    margin: 0;
    color: #01153a
  }

  .trusted-partners .trusted-partners__content {
    margin-top: 1rem;

    font-weight: 400;

    color: #FFF;
    text-align: center
  }

  .trusted-partners .trusted-partners__content p {
    margin: 0
  }

  .trusted-partners .trusted-partners__carousel .img-responsive {
    height: auto;
    width: auto;
    max-width: 100%
  }

  .trusted-partners .trusted-partners__carousel .sliderDesktop .img-responsive {
    height: 80px;
    width: auto
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots {
    position: static;
    margin-top: 4rem
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots button {
    display: none
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots li {
    margin: 0 4px;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, .6);
    opacity: .5;
    display: inline-block;
    border-radius: 100%;
    cursor: pointer
  }

  .trusted-partners .trusted-partners__carousel ul.slick-dots li.slick-active {
    background: #FFF;
    opacity: 1
  }

  .trusted-partners .trusted-partners__list.sliderDesktop {
    margin-top: 5rem;
    margin-bottom: 0 !important
  }

  .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
    margin-right: -100px
  }

  .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
    margin-top: 0;
    padding-bottom: 4px;
    margin-right: 100px;
    text-align: center;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
  }

  .trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: hidden
  }

  #mgd-partners.trusted-partners .trusted-partners__carousel-wrapper {
    overflow-x: visible;
  }

  #mgd-partners.trusted-partners h2.trusted-partners__title {
    text-align: center;
    margin: 0 0 25px;
    color: #fff;
    max-width: inherit;
  }

  @media(min-width: 992px) {
    .trusted-partners .trusted-partners__list.sliderMobile {
      display: flex;
      flex-wrap: wrap;
      margin-right: -.5rem;
      margin-left: -.5rem;
      justify-content: space-around;
      padding: 0
    }

    .trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      flex: inherit;
      max-width: 160px;
      padding: 0;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 4rem;
      align-items: center;
      display: flex;
    }

    .trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__image-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-height: 70px;
      height: auto;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center
    }

    #mgd-partners.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      max-width: 235px;
    }
  }



  @media(max-width: 991px) {

    .trusted-partners .trusted-partners__carousel .slick-track {
      margin: 0;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }

    .trusted-partners .trusted-partners__carousel .slick-track .trusted-partners__item {
      margin-top: 3rem;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0
    }

    .trusted-partners .trusted-partners__carousel .slick-track .trusted-partners__image-wrapper {
      height: 55px;
      width: auto
    }

    .trusted-partners .slick-slide>div {
      margin-top: 3rem;
      width: calc(50% - 3rem);
      display: inline-block;
      margin-right: 3rem
    }

    .trusted-partners .slick-slide>div img.trusted-partners__image {
      height: 55px;
      width: auto
    }

    .trusted-partners .trusted-partners__carousel .slick-slider {}

    .trusted-partners h2.trusted-partners__title {
      max-width: 60%;
      display: block;
      margin: 0 auto;
      text-align: center
    }



    .trusted-partners h2.trusted-partners__title {
      max-width: 60%;
      display: block;
      margin: 0 auto;
      text-align: center
    }



    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item .trusted-partners__image-wrapper {
      margin-bottom: 2rem;
      margin-top: 0;
      margin-right: 0;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
      flex: 0 0 25%;
      max-width: 25%;
      margin: 0;
      margin-right: 50px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
      margin-right: -50px;
    }

    .trusted-partners .trusted-partners__carousel .sliderDesktop .img-responsive {
      height: 55px;
      width: auto;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__item {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper {
      width: 100%;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(50% - 1.5rem);
      margin-right: 1.5rem;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div:nth-child(even) {
      margin-left: 1.5rem;
      margin-right: 0;
    }

    /*   .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__carousel {
padding: 0 15px;
} */
    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper img.trusted-partners__image {
      display: block;
      margin: 0 auto;
    }

    .layout-2 .trusted-partners__title,
    .layout-2 .trusted-partners__content {
      max-width: 100% !important
    }

    .layout-2.trusted-partners .trusted-partners__list.sliderMobile div:nth-child(1),
    .layout-2.trusted-partners .trusted-partners__list.sliderMobile div:nth-child(2) {
      margin-top: 0px;
    }

    .layout-2.trusted-partners .trusted-partners__list.sliderMobile .trusted-partners__item {
      max-width: 100%;
    }

    .layout-2.trusted-partners .slick-slide>div {
      margin-top: 1rem;
    }

    .features-info .features-info__content .mobile-only br {
      display: block;
    }
  }

  @media (max-width:767px) {
    .layout-2 .hs_cos_wrapper_type_cta .cta_button {
      font-size: 0.75rem
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .trusted-partners__item {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0;
      margin-right: 30px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-list {
      margin-right: -30px;
    }

    .trusted-partners .trusted-partners__list.sliderDesktop .slick-slide>div {
      margin-right: 30px;
      width: calc(50% - 30px);
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .layout-2 .content-area {
      max-width: 100%;
    }

    .layout-2 .trusted-partners__carousel-wrapper {
      width: 100%;
      margin-top: 40px;
    }

    .layout-2 .slick-track {
      width: 100% !important;
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(33% - 0rem);
      margin: 0rem !important;
    }

    .layout-2 .trusted-partners__title,
    .layout-2 .trusted-partners__content {
      text-align: center !important;
    }

    .layout-2 .hs_cos_wrapper_type_cta .cta_button {
      margin-left: auto;
      margin-right: auto;
    }

    #mgd-partners {
      padding: 1rem 0;
    }

    #mgd-partners.trusted-partners h2.trusted-partners__title br {
      display: none;
    }

  }


  @media(max-width: 575.98px) {
    .trusted-partners .trusted-partners__container {
      margin-top: 3rem;
      width: calc(50% - 2rem);
      display: inline-block;
      height: 55px;
      margin-right: 2rem;
    }

    .trusted-partners .slick-slide>div:nth-child(even) {
      margin-right: 0;
      margin-left: 2em;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container {
      height: auto;
      width: 100%;
      margin-right: auto !important;
      margin-left: auto !important;
      display: block;
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container .slick-slide>div {
      width: calc(50% - 0rem);
    }

    .layout-2.trusted-partners.sliderMobile_wrap>.trusted-partners__container {
      margin-top: 0px;
    }

    .slick-initialized .slick-slide {
      display: flex;
      flex-wrap: wrap;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper {
      width: 100%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      height: auto;
      padding: 0 15px;
    }

    .trusted-partners.sliderMobile_wrap>.trusted-partners__container .trusted-partners__image-wrapper img.trusted-partners__image {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      float: left;
    }

    .features-info {
      padding: 286px 0 5rem;
    }

  }