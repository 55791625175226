.testimonials_wrap .slick-list{
    backdrop-filter:blur(15px);
    background-color:hsla(0,0%,100%,.1)
}
.testimonials_wrap{
    padding:6rem 0;
    background-size:cover
}
.testimonials_wrap .testimonials__title{
    text-align:center;
    color:#fff;
    margin:0;
    font-weight:800
}
.testimonials_wrap .testimonials_slider_row{
    margin-top:3rem
}
.testimonials_wrap .testimonial__content{
    text-align:left;
    font-size:20px;
    line-height:25px;
    letter-spacing:0;
    color:#fff;
    width:calc(100% - 300px);
    position:relative;
    padding:40px
}
.testimonials_wrap .testimonial__content p{
    font-size:20px;
    line-height:24px
}
.testimonials_wrap .testimonial__content:before{
    left:-15px;
    top:0
}
.testimonials_wrap .testimonial__content .desktop-only:after,.testimonials_wrap .testimonial__content:before{
    content:"";
    background-image:url(https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/testemonials.icon.svg);
    width:78px;
    position:absolute;
    height:60px;
    opacity:.8;
    z-index:-1;
    background-repeat:no-repeat;
    background-size:contain
}
.testimonials_wrap .testimonial__content .desktop-only:after{
    right:-40px;
    bottom:-60px;
    transform:rotate(180deg)
}
.testimonials_wrap .testimonial__content>div{
    position:relative
}
.testimonials_wrap .testimonial__content>div:after{
    content:"";
    background-image:url(https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/testemonials.icon.svg);
    width:78px;
    position:absolute;
    right:-40px;
    bottom:-60px;
    height:60px;
    opacity:.8;
    z-index:-1;
    background-repeat:no-repeat;
    background-size:contain;
    transform:rotate(180deg)
}
.testimonials_wrap .testimonial__content .desktop-only{
    position:relative
}
.testimonials_wrap .testimonials{
    text-align:left;
    display:inline-flex;
    height:100%
}
.testomonial-box{
    position:relative;
    padding:40px;
    width:100%;
    display:flex;
    justify-content:space-between;
    height:100%
}
.testimonials_wrap .testimonial__author{
    margin-top:0;
    text-align:left;
    margin-bottom:5px;
    width:260px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    text-align:center;
    align-items:center;
    align-content:center;
    height:100%
}
.testimonials_wrap .testimonial__author>span{
    display:block;
    width:100%;
    float:left;
    text-align:center
}
.testimonials_wrap .testimonial__author-image{
    margin:0 0 10px;
    max-width:100px;
    height:auto;
    border-radius:100%
}
.testimonials_wrap .testimonial__full-name{
    display:block;
    text-align:left;
    font-size:20px;
    line-height:25px;
    font-weight:600;
    letter-spacing:0;
    color:#01153a;
    margin-top:4px
}
.testimonials_wrap .testimonial__role{
    display:block;
    color:#fff;
    font-size:20px;
    line-height:25px
}
.testimonials_wrap .testimonial__logo{
    margin:1rem 0 0;
    max-width:100%
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots{
    position:static;
    margin-top:3rem;
    margin-bottom:0;
    text-align:center;
    -webkit-transition:opacity .3s;
    -o-transition:.3s opacity;
    transition:opacity .3s;
    -webkit-transform:translateZ(0);
    transform:translateZ(0);
    z-index:10;
    padding:0
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots li button{
    display:none
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots li{
    background:hsla(0,0%,100%,.6);
    display:inline-block;
    border-radius:100%;
    margin:0 4px;
    opacity:.5;
    width:8px;
    height:8px
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots li.slick-active{
    background:#fff;
    opacity:1
}
.testimonials_wrap .testimonial__content p:last-child,.testimonials_wrap .testimonials_slider_row.slick-slider.slick-dotted{
    margin-bottom:0
}
.testimonials_wrap .testimonials:nth-child(2) .testimonial__content p:last-child{
    display:inline-block
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots li{
    background:hsla(0,0%,100%,.6)!important
}
.testimonials_wrap .testimonials_slider_row ul.slick-dots li.slick-active{
    background-color:#fff!important
}
.testimonials_wrap .slick-next:before,.testimonials_wrap .slick-prev:before{
    content:"";
    background-image:url(https://f.hubspotusercontent30.net/hubfs/19825781/Anzu_August2021/images/next.png);
    width:15px;
    height:28px;
    position:absolute;
    background-repeat:no-repeat;
    opacity:1;
    background-size:contain;
    display:inline-block!important
}
.testimonials_wrap .slick-prev:before{
    background-image:url(https://f.hubspotusercontent30.net/hubfs/19825781/Anzu_August2021/images/prev.png)
}
.testimonials_wrap .slick-prev{
    left:-42px
}
.testimonials_wrap .slick-next,.testimonials_wrap .slick-prev{
    top:37%
}
.testomonial-box .mobile-only{
    width:100%
}
.testimonials_slider_row .slick-track{
    display:flex!important
}
.testimonials_slider_row .slick-slide{
    height:inherit!important
}
@media (min-width:768px) and (max-width:1199.98px){
    .testimonials_wrap .testimonials_slider_row ul.slick-dots{
        margin-top:3.2rem
    }
}
@media (max-width:991.98px){
    .testimonials_wrap{
        padding:3.75rem 0
    }
    .testomonial-box{
        flex-wrap:wrap;
        padding:25px;
        justify-content:flex-start;
        align-items:flex-start;
        display:inline-block
    }
    .testimonials_wrap .testimonial__author,.testimonials_wrap .testimonial__content,.testimonials_wrap .testimonial__full-name{
        text-align:center;
        width:100%
    }
    .testimonials_wrap .testimonial__content:before{
        left:0;
        top:0;
        height:50px
    }
    .testimonials_wrap .testimonial__content .mobile-only:after,.testimonials_wrap .testimonial__content>div:after{
        content:"";
        background-image:url(https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/testemonials.icon.svg);
        width:78px;
        position:absolute;
        right:0;
        bottom:-60px;
        z-index:-1;
        height:50px;
        background-repeat:no-repeat;
        background-size:contain;
        transform:rotate(180deg)
    }
    .testimonials_wrap .testimonial__author-image{
        max-width:90px
    }
    .testimonials_wrap .testimonial__full-name,.testimonials_wrap .testimonial__role{
        font-size:15px;
        font-family:Montserrat,sans-serif;
        line-height:18px
    }
    .testimonials_wrap .testimonial__content p{
        font-size:16px;
        line-height:20px
    }
}
@media (max-width:991.98px){
    .testimonials_wrap .testimonial__author-image{
        max-width:50px
    }
}
@media (max-width:768px){
    .testimonials_wrap .testimonial__content{
        padding:36px 10px
    }
    .testimonials_wrap .testimonial__content:before{
        height:40px;
        width:50px;
        left:-10px;
        top:5px
    }
    .testimonials_wrap .testimonial__content .mobile-only:after,.testimonials_wrap .testimonial__content>div:after{
        height:40px;
        width:50px
    }
    .testimonials_wrap .testimonial__content p{
        font-size:15px;
        line-height:17px
    }
    .testimonials_wrap .testimonial__content{
        padding:36px 0
    }
}
@media (max-width:600px){
    .testimonials__container .slick-next,.testimonials__container .slick-prev{
        display:none!important
    }
}
