.button-module{
    text-align:center
}
.button-module .button-module-link{
    white-space:nowrap;
    cursor:pointer;
    -webkit-transition:all .3s ease-in;
    -o-transition:all .3s ease-in;
    transition:all .3s ease-in;
    color:#01153a;
    background:#00ffff;
    border:2px solid #01153a;
    padding:.5rem 1.5rem;
    border-radius:22.5px;
    font-size:.875rem;
    font-weight:400;
    line-height:1.25rem;
    min-width:200px;
    display:inline-block;
    margin-left:1rem;
    text-transform:uppercase;
    width:auto;
    text-align:center
}
.button-module .button-module-link:hover{
    border:2px solid #fff;
    background:#fff;
    color:#01153a
}
.button-module .button-module-link:first-child{
    margin-left:0
}
@media(max-width:991.98px){
    .button-module .button-module-link:first-child{
        margin-left:auto;
        margin-top:2rem;
        margin-bottom:1rem
    }
}
@media(max-width:767px){
    .button-module .button-module-link{
        margin-left:auto;
        margin-top:2rem;
        margin-bottom:1rem
    }
    .button-module .button-module-link:last-child{
        margin-left:auto;
        margin:0 auto
    }
    .button-module .button-module-link:first-child{
        margin-top:0;
        margin-left:auto;
        margin-right:auto
    }
    .button-module .button-module-link{
        font-size:.75rem
    }
}
