.home_banner{
    width:100%;
    height:calc(var(--vh, 1vh)*90);
    position:relative;
    padding:0
}
.home_banner .hero__video{
    width:100%;
    height:100%;
    object-fit:cover
}
.home_banner .hero__container{
    position:relative
}
.home_banner:after{
    content:"";
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background-size:cover;
    padding-top:35%
}
.home_banner .hero__content-wrapper{
    position:absolute;
    bottom:7.5rem;
    left:3.5rem;
    z-index:1
}
.home_banner .hero__title{
    width:auto;
    display:inline-block;
    position:relative;
    margin:0;
    text-transform:uppercase;
    color:#fff
}
.home_banner .hero__content{
    font-weight:400;
    color:#fff
}
.home_banner .hero__content p{
    margin:1em 0
}
@media (max-width:991.98px){
    .home_banner .hero__content-wrapper{
        left:1rem;
        bottom:2.5rem
    }
    .home_banner:after{
        padding-top:100%;
        background-position:20%
    }
}
@media(max-width:575.98px){
    .home_banner .hero__container{
        position:static
    }
    .home_banner .hero__content-wrapper{
        left:2rem;
        bottom:2.5rem;
        width:calc(100vw - 4rem);
        right:2rem
    }
}
@media (max-width:480px){
    .home_banner{
        margin-top:-70px;
        height:100vh!important
    }
    .home_banner .hero__content br,.home_banner .hero__content p br{
        display:none
    }
    .banner-section.home-banner .home_banner.hero.cm_banner .hero__title{
        font-size:1.8rem!important
    }
}
@media (max-width:340px){
    .home_banner .hero__content-wrapper{
        left:1rem;
        width:calc(100vw - 3rem);
        right:1rem
    }
}
