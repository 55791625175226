.content-wrapper--vertical-spacing,.dnd-section{
    padding:7.5rem 0
}
@media (max-width:767.98px){
    .content-wrapper--vertical-spacing,.dnd-section{
        padding:5.0000000025rem 0
    }
}
@media (min-width:576px){
    .content-wrapper,.dnd-section>.row-fluid{
        max-width:539.9999991692307px
    }
}
@media (min-width:768px){
    .content-wrapper,.dnd-section>.row-fluid{
        max-width:720px
    }
}
@media (min-width:992px){
    .content-wrapper,.dnd-section>.row-fluid{
        max-width:960px
    }
}
@media (min-width:1200px){
    .content-wrapper,.dnd-section>.row-fluid{
        max-width:1170px
    }
}
body{
    font-family:Montserrat;
    font-size:1rem;
    font-weight:400;
    line-height:1.5rem
}
a,body{
    color:#000
}
em{
    font-style:italic
}
a:active,a:focus,a:hover{
    color:#000
}
h1,h2{
    font-size:40px;
    letter-spacing:0
}
h1,h2,h3{
    color:#000;
    font-family:Montserrat;
    font-weight:800
}
h3{
    font-size:30px;
    line-height:2.25rem
}
h4,h5,h6{
    color:#000;
    font-family:Montserrat;
    font-size:20px;
    font-weight:800
}
@media (max-width:1660px){
    body{
        font-size:18px;
        line-height:22px
    }
    h1,h2{
        font-size:37px;
        line-height:37px
    }
    h3{
        font-size:27px;
        line-height:27px
    }
}
@media (max-width:1199.98px){
    body{
        font-size:15px;
        line-height:18px
    }
    h1,h2{
        font-size:37px;
        line-height:37px
    }
    h3{
        font-size:27px;
        line-height:27px
    }
}
@media (max-width:767px){
    body{
        font-size:15px;
        line-height:18px
    }
    h1,h2{
        font-size:28px;
        line-height:28px
    }
    h3{
        font-size:21px;
        line-height:21px
    }
}
blockquote{
    border-left-color:#000
}
.button,button{
    background-color:#fff;
    border:2px solid #000;
    border-radius:22.5px;
    color:#000;
    padding:.5rem 1.5rem
}
.button:hover,button:hover{
    background-color:#000;
    border-color:#000;
    color:#fff
}
.button:focus,button:focus{
    background-color:#01153a;
    border-color:#01153a;
    color:#fff
}
.button:active,button:active{
    background-color:#000;
    border-color:#000;
    color:#fff
}
.submitted-message,form{
    color:#000;
    font-size:1em;
    font-weight:400
}
.submitted-message{
    line-height:1.3em
}
.form-title{
    background-color:hsla(0,0%,100%,0);
    color:#01153a
}
form label,form legend{
    color:#000
}
form input[type=email],form input[type=file],form input[type=number],form input[type=password],form input[type=tel],form input[type=text],form select,form textarea{
    background-color:hsla(0,0%,100%,.3);
    border-color:#000;
    color:#000
}
form input[type=email]:focus,form input[type=file]:focus,form input[type=number]:focus,form input[type=password]:focus,form input[type=tel]:focus,form input[type=text]:focus,form select:focus,form textarea:focus{
    border-color:#000
}
::-webkit-input-placeholder,textare::placeholder{
    color:#fff;
    opacity:1
}
:-moz-placeholder,:-ms-input-placeholder,::-moz-placeholder,::-webkit-input-placeholder,::placeholder,textare::-webkit-input-placeholder{
    color:#fff;
    opacity:1
}
.hs-fieldtype-date .input .hs-dateinput:before{
    color:#000
}
.fn-date-picker td.is-selected .pika-button{
    background:#000
}
.fn-date-picker td .pika-button:hover{
    background-color:#000!important
}
.fn-date-picker td.is-today .pika-button{
    color:#000
}
form .hs-button,form input[type=submit]{
    background-color:#fff;
    border:2px solid #000;
    border-radius:22.5px;
    color:#000;
    padding:.5rem 1.5rem
}
form .hs-button:hover,form input[type=submit]:hover{
    background-color:#000;
    border-color:#000;
    color:#fff
}
form .hs-button:focus,form input[type=submit]:focus{
    background-color:#01153a;
    border-color:#01153a;
    color:#fff
}
form .hs-button:active,form input[type=submit]:active{
    background-color:#000;
    border-color:#000;
    color:#fff
}
.form-columns-1 .hs-input{
    min-width:100%
}
table{
    background-color:#fff
}
table,td,th{
    border-color:#2f323a
}
td,th{
    color:#2f323a
}
thead td,thead th{
    background-color:#2f323a;
    border-bottom-color:#2f323a;
    color:#fff
}
tfoot td{
    background-color:#fff;
    color:#2f323a
}
header.header{
    background-color: #01153a;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.9); /* Add shadow below */
    border-bottom: none; /* Remove default border */
}
.header__language-switcher .lang_list_class li a,.header__language-switcher-label-current,.header__logo .logo-company-name,body .navigation-primary a{
    color:#fff;
    font-family:Montserrat
}
.header__language-switcher .lang_list_class li a:focus,.header__language-switcher .lang_list_class li:hover a,.header__language-switcher-label-current:focus,.header__language-switcher-label-current:hover,body .navigation-primary a:focus,body .navigation-primary a:hover,nav.custom-menu-primary .hs-menu-wrapper>ul>li>a:hover{
    color:#d7d7d7
}
.header__language-switcher .lang_list_class li a:active,.header__language-switcher-label-current:active,body .navigation-primary a:active{
    color:#fff
}
body .navigation-primary .submenu.level-1>li>a.active-item:after{
    background-color:#000
}
.header__language-switcher .lang_list_class,body .submenu.level-2{
    background-color:rgba(0,25,38,.7)
}
nav.custom-menu-primary .hs-menu-wrapper>ul>li>a{
    color:#fff
}
nav.custom-menu-primary .hs-menu-wrapper>ul>li.hs-item-has-children{
    padding:.5rem
}
nav.custom-menu-primary .hs-menu-wrapper>ul>li.active{
    background-color:hsla(0,0%,100%,0)
}
nav.custom-menu-primary .hs-menu-wrapper>ul>li.active>a{
    color:#01153a!important
}
nav.custom-menu-primary .hs-menu-wrapper>ul ul{
    background-color:#001926
}
nav.custom-menu-primary .hs-menu-wrapper>ul ul li>a{
    color:#fff
}
nav.custom-menu-primary .hs-menu-wrapper>ul ul li:hover>a{
    background-color:hsla(0,0%,100%,0);
    color:#fff
}
nav.custom-menu-primary .hs-menu-wrapper>ul ul li.active>a{
    background-color:hsla(0,0%,100%,0);
    color:#01153a
}
nav.custom-menu-primary .hs-menu-wrapper>ul>li:hover>a:after{
    background-color:#fff
}
.header__language-switcher .lang_list_class li:hover,body .submenu.level-2 .menu-item .menu-link:focus,body .submenu.level-2 .menu-item .menu-link:hover,body .submenu.level-2>li:first-child.focus:before,body .submenu.level-2>li:first-child:hover:before{
    background-color:rgba(0,25,38,.7)
}
.header__language-switcher .lang_list_class.first-active:after{
    border-bottom-color:rgba(0,25,38,.7)
}
.header__language-switcher .lang_list_class li a,.header__language-switcher-label-current{
    font-family:Montserrat
}
.header__language-switcher-label-current:after{
    border-top-color:#fff
}
@media(max-width:767px){
    .header__navigation{
        background-color:rgba(0,25,38,.7)
    }
    .header__navigation-toggle svg,.menu-arrow svg{
        fill:#fff
    }
}
.footer,.site-footer__mega-footer{
    background-color:#01153a !important;
    color:#fff
}
.footer-top h3.title,.footer-top h3.title *,.footer-top ul li a,.footer-top ul li a:hover,.site-footer__menu .hs-menu-wrapper ul li a,.site-footer__menu .hs-menu-wrapper ul li a:hover,.site-footer__social.social ul li a,.site-footer__social.social ul li a:hover,h4.site-footer__column-title{
    color:#000
}
.footer_form input[type=email],.footer_form input[type=email]:hover,.footer_form input[type=text],.footer_form input[type=text]:hover{
    background-color:rgba(0,0,0,.3);
    color:#fff
}
.footer_form input.error{
    background-color:rgba(178,0,29,.6);
    color:#fff
}
.footer_form input[type=email]:focus,.footer_form input[type=text]:focus{
    background-color:rgba(0,0,0,.3)
}
.footer_form input.error:hover{
    background-color:rgba(178,0,29,.6);
    color:#fff
}
.footer_form input.hs-button,.footer_form input.hs-button:focus:not(:hover){
    background-color:#000;
    border-color:#000;
    color:#fff
}
.footer_form input.hs-button:hover{
    background-color:transparent;
    border-color:#000;
    color:#000
}
.blog-card__tag-link,.blog-card__tag-link:active,.blog-card__tag-link:focus,.blog-card__tag-link:hover,.blog-card__title a,.blog-card__title a:active,.blog-card__title a:focus,.blog-card__title a:hover,.blog-post__author-name,.blog-post__author-name:active,.blog-post__author-name:focus,.blog-post__author-name:hover,.blog-post__tag-link,.blog-post__tag-link:active,.blog-post__tag-link:focus,.blog-post__tag-link:hover,.blog-tag-filter__menu-link,.blog-tag-filter__menu-link:active,.blog-tag-filter__menu-link:focus,.blog-tag-filter__menu-link:hover{
    color:#000
}
.blog-tag-filter__menu-link--active-item:after{
    background-color:#000
}
.blog-pagination__link{
    color:#000
}
.blog-pagination__link--active:after,.blog-pagination__next-link:after,.blog-pagination__prev-link:after{
    background-color:#000
}
.blog-post__title{
    color:#000;
    font-family:Montserrat;
    font-size:2.5px;
    font-weight:800
}
.blog-post__author{
    background-color:#000
}
.blog-index__post-content a:hover,.blog-post__back-to-blog:hover,.blog-post__meta a:hover,.blog-sidebar a:hover{
    color:#000
}
.blog-post__read-more a:hover svg,a.blog-post__back-to-blog:hover svg{
    fill:#000
}
.blog-index__post-content--small h2{
    font-size:1.9px
}
.blog-index__post-content a{
    color:#000
}
.blog-post__read-more svg{
    fill:#000
}
.blog-post__back-to-blog,.blog-post__meta a,.blog-post__meta a:hover,.blog-sidebar a{
    color:#000
}
.blog-post__back-to-blog svg{
    fill:#000
}
.card__price{
    color:#000;
    font-family:Montserrat;
    font-size:1.9px;
    font-weight:800
}
.row-fluid-wrapper .widget_1628058824039-hidden{
    display:block!important
}
.widget_1628058824039-hidden ul{
    display:flex;
    flex-wrap:wrap
}
.widget_1628058824039-hidden ul li{
    float:left;
    margin-bottom:15px;
    text-align:center;
    width:100%
}
.module_16280707347413-hidden .hs-form .hs-button{
    display:block;
    font-size:13px;
    font-weight:600;
    margin:2rem auto 0;
    padding:.5rem 2.5rem;
    width:max-content
}
.widget_1627984306492-flexbox-positioning>div,.widget_1642427723920-flexbox-positioning>div{
    text-align:center
}
#omup--sec .hs-submit{
    padding-top:15px
}
@media(max-width:600px){
    #omup--sec .HsFormsModule-inner-content-part form fieldset.form-columns-3 .hs-form-field{
        width:100%
    }
    #omup--sec .HsFormsModule-inner-content-part form fieldset.form-columns-2>div:first-child,#omup--sec .HsFormsModule-inner-content-part form fieldset.form-columns-3>div:first-child{
        padding-right:0
    }
    #omup--sec .HsFormsModule-inner-content-part form fieldset.form-columns-2>div:last-child,#omup--sec .HsFormsModule-inner-content-part form fieldset.form-columns-3>div:last-child{
        padding-left:0
    }
}
