@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight:800;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight:700;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight:500;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight:400;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight:600;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight:200;
    font-style:normal;
    font-display:swap
}
@font-face{
    font-family:'Montserrat';
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.eot');
    src:url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.woff2') format('woff2'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.woff') format('woff'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.ttf') format('truetype'),url('https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight:300;
    font-style:normal;
    font-display:swap
}
