.HsFormsModule-inner-wrapper{display: flex; justify-content: space-between;align-items: flex-start;}
.HsFormsModule-inner-wrapper.btn-popup-right{align-items:center;}
.HsFormsModule_container {border-top: none;	clear: both;	background:transparent;}
.HsFormsModule-inner-content-part {border-top: none;	clear: both;	float: left;	background:transparent;	width:100%;}
.HsFormsModule_content { width: 100%;margin: 0 auto;padding:0 20px;}
.HsFormsModule_content .HsFormsModule-main-heading{ 
  margin:60px auto 68px auto;
  font-size: 20px; 
  font-weight: 600;
  line-height: 1.6; 
  text-align: center;
  color: #434957;
  max-width: 1000px;
}
.HsFormsModule-inner-content-part h2 {
  width: auto;
  text-transform: uppercase;
  position: relative;
  color: #000000;
  font-weight: 800;
  display: block;
  margin: 0 auto 1.563rem;
  text-align: center;
}
.HsFormsModule-inner-content-part div.performance-content-wrapper { display: block;padding-top: 0;align-items: center;}
.HsFormsModule-inner-content-part form .hs-button {margin: 0 auto;white-space: nowrap;cursor: pointer;text-transform: uppercase;-webkit-transition: all .3s ease-in;-o-transition: all .3s ease-in;transition: all .3s ease-in;padding: .5rem 1rem;border-radius: 25px;font-size:0.875rem;font-weight: 400;line-height: 1.25rem;color: #FFF;background: #000;border: 2px solid #000;font-family: inherit;min-width:162px;}
.HsFormsModule-inner-content-part form .hs-button:hover,.HsFormsModule-inner-content-part form input.hs-button:focus { text-decoration: none;background-color: #fff;border-color: #fff;color: #000; }
.HsFormsModule-inner-content-part form.hs-form .legal-consent-container{margin-top:10px;}
.HsFormsModule-inner-content-part form.hs-form .legal-consent-container .hs-form-booleancheckbox-display>span{color:#000;}
.HsFormsModule-inner-content-part form.hs-form .legal-consent-container .hs-form-booleancheckbox-display>span:after{content: '';width: 1.438rem;height: 1.438rem;position: absolute;top: 5px; left: 0;border: 1px solid #1D7A76;border-radius: 0px;background-color: #1D7A76;}
.HsFormsModule-inner-content-part form.hs-form .legal-consent-container .hs-form-booleancheckbox-display>span:before {content: '';width: 0.938rem;height: 0.938rem;position: absolute;top: calc(.25rem + 5px);left: .25rem;background: #FFF;border-radius: 0;opacity: 0;-webkit-transition: opacity .4s ease-in;-o-transition: opacity .4s ease-in;transition: opacity .4s ease-in;z-index: 1;}
.HsFormsModule-inner-content-part form.hs-form .form__input-wrapper--checkbox-is-checked .hs-form-booleancheckbox-display>span:before {opacity: 1 !important;}
.HsFormsModule-inner-content-part form.hs-form .legal-consent-container .hs-form-booleancheckbox-display p a{
  color:#000;
  font-weight: 500;
}
.HsFormsModule-inner-content-part form .hs-form-field.hs-fieldtype-booleancheckbox input:checked+span:before {
    opacity: 1;
}
.HsFormsModule-inner-content-part div.performance-content-wrapper .submitted-message {
    min-height: 30px;
    text-align: center;
}
/**********************/
/*.HsFormsModule-inner-content-part form {padding: 0px 0 20px;}*/
/************************/
.HsFormsModule-inner-content-part form,.HsFormsModule-inner-content-part form fieldset{max-width:1170px;margin: 0 auto;}
.HsFormsModule-inner-content-part form fieldset.form-columns-3 .hs-form-field{width: 33.33%;}
.HsFormsModule-inner-content-part form .hs-form-field:not(.hs-fieldtype-radio)>label {display: block;margin-bottom: 5px;color: #33475b;font-size: 13px;line-height: 20px;font-weight: 300;}
.HsFormsModule-inner-content-part form .hs-form-field.hs-fieldtype-radio>label {font-size: 15px;}
.HsFormsModule-inner-content-part form>fieldset>div, .HsFormsModule-inner-content-part form>.hs-form-field{margin-bottom: 10px;}
.HsFormsModule-inner-content-part form>div{text-align:center;}
.HsFormsModule-inner-content-part form .inputs-list{margin-bottom:0}
.HsFormsModule-inner-content-part form .hs-form-field input:not([type=submit]):not([type=reset]):not([type=radio]):not([type=checkbox]):not([type=button]), .HsFormsModule-inner-content-part form .hs-form-field textarea, .HsFormsModule-inner-content-part form .hs-form-field select {height: 36px;border: 0;border-radius: 0;padding: .5rem .938rem;background: rgba(0,0,0,.32);font-size: 1rem;font-weight: 400;line-height: 1.25rem;color: #FFF;}
.HsFormsModule-inner-content-part form .hs-form-field input:not([type=submit]):not([type=reset]):not([type=radio]):not([type=checkbox]):not([type=button])::placeholder, .HsFormsModule-inner-content-part form .hs-form-field textarea::placeholder, .HsFormsModule-inner-content-part form .hs-form-field select::placeholder {color:#ffffff;}
.HsFormsModule-inner-content-part form ::-webkit-input-placeholder {color:#ffffff;}
.HsFormsModule-inner-content-part form :-moz-placeholder {color:#ffffff;opacity:1;}
.HsFormsModule-inner-content-part form ::-moz-placeholder {color:#ffffff;opacity:1;}
.HsFormsModule-inner-content-part form :-ms-input-placeholder {color:#ffffff;}
.HsFormsModule-inner-content-part form .hs-richtext,.HsFormsModule-inner-content-part form .hs-richtext p, .HsFormsModule-inner-content-part form .hs-form label {font-size: 0.875rem;color: #1A202C;}
.HsFormsModule-inner-content-part form .hs-richtext p a {font-size: 0.875rem;text-decoration: underline;}
.HsFormsModule-inner-content-part form fieldset.form-columns-2 .input, .HsFormsModule-inner-content-part form fieldset.form-columns-3 .input,  .HsFormsModule-inner-content-part form fieldset.form-columns-1 .input {margin-right:0;width:100%;padding-right: 0px;padding-left: 0px;}
.HsFormsModule-inner-content-part form ul.no-list.hs-error-msgs.inputs-list{float: none;width: auto;padding: 0;line-height: 20px;text-align: left;white-space: normal;font-weight: normal;color: red;display: block;font-size: 15px;}
.HsFormsModule-inner-content-part form ul.no-list.hs-error-msgs.inputs-list li {padding: 5px 0 0;}
.HsFormsModule-inner-content-part form .hs_error_rollup {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.HsFormsModule-inner-content-part form ul.hs-error-msgs label {
  font: 14px/1 Montserrat,sans-serif;
  font-size:14px;
  line-height:1;
  font-family:'Montserrat',sans-serif;
  text-align:left;
  border: none;
  color: #f02;
  padding: 0;
}
.HsFormsModule-inner-content-part form .hs-form-field.hs-fieldtype-booleancheckbox input,.HsFormsModule-inner-content-part form .hs-form-field.hs-fieldtype-checkbox input{margin-right: 8px;margin-top: 6px;}

.HsFormsModule-inner-content-part form .hs-form-field select{padding-right: calc(.938rem + 19px);background-image: url(https://f.hubspotusercontent30.net/hubfs/19825781/arrow-down.png);background-position: calc(100% - 10px) 50%;background-repeat: no-repeat;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-appearance: none;-moz-appearance: none;}
.HsFormsModule-inner-content-part form .hs-form-field select::placeholder,
.HsFormsModule-inner-content-part form .hs-form-field select::-webkit-input-placeholder,
.HsFormsModule-inner-content-part form .hs-form-field select::-moz-placeholder,
.HsFormsModule-inner-content-part form .hs-form-field select:-ms-input-placeholder,
.HsFormsModule-inner-content-part form .hs-form-field select:-moz-placeholder {color: #354062;}

.HsFormsModule-inner-content-part form .hs_recaptcha.hs-recaptcha.field.hs-form-field{margin-top: 10px;margin-bottom: 15px;}
.HsFormsModule-inner-content-part .hbspt-form .submitted-message{background-color:transparent;}
.HsFormsModule-inner-content-part .hs_country.hs-country.hs-fieldtype-select .input {   background: transparent!important;}

#mgd-newsletter .legal-consent-container .hs-form-booleancheckbox-display>span p {
    font-size: 16px !important;
    line-height: 19.2px;
    padding: 3px 0 0 4px;
    display: inline-block;
}
#mgd-newsletter .HsFormsModule_container {
    padding: 0 30px;
}
#mgd-newsletter .legal-consent-container .hs-form-booleancheckbox-display>span p>a {
    font-weight: bold;
}
.cm_website_page .footer-form form.hs-form .hs-fieldtype-select .input {  padding-right: 0;  padding-left: 0;}
.body-container--home .footer-form .hs-form .hs-fieldtype-select .input:after {content:none;}

@media screen and (min-width: 480px) {
  .HsFormsModule-inner-content-part form fieldset.form-columns-2>div:first-child,.HsFormsModule-inner-content-part form fieldset.form-columns-3>div:first-child{padding-right: 1.5%;}
  .HsFormsModule-inner-content-part form fieldset.form-columns-2>div:last-child,.HsFormsModule-inner-content-part form fieldset.form-columns-3>div:last-child{padding-left: 1.5%;}
}

@media (max-width: 767px){
  
  #mgd-newsletter .HsFormsModule_container {
      padding: 0;
  }
  #mgd-newsletter .HsFormsModule_content {
      padding: 0;
  }
  
  #mgd-newsletter .legal-consent-container .hs-form-booleancheckbox-display>span p {
      font-size: 14px !important;
      line-height: 18px;
  }
  .HsFormsModule-inner-content-part form .hs-button{font-size:0.75rem;}
}
@media screen and (max-width: 992px) {
  .HsFormsModule-inner-wrapper{display: block; float: left; width: 100%;}
  .HsFormsModule-inner-content-part {width: 100%;max-width: 100%;}
}
@media (max-width: 400px), (max-device-width: 480px) and (min-device-width: 320px) {
  .HsFormsModule-inner-content-part form fieldset.form-columns-2>div:first-child {margin-bottom: 15px;}
.HsFormsModule-inner-content-part form .hs-form-field input:not([type=submit]):not([type=reset]):not([type=radio]):not([type=checkbox]):not([type=button]), .HsFormsModule-inner-content-part form .hs-form-field textarea, .HsFormsModule-inner-content-part form .hs-form-field select{width:100%;}
}

